<script>
export default {
  data() {
    return {
      lastUpdateTime: "",
      flatWords: () => {
        if (this.words.length > 0) {
          return this.words.map((item, index) => {
            return item[0]
          });
        }
        else {
          return [
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют"
          ];
        }
      },
      topWords: () => {
        if (this.words.length > 0) {
          return this.words.filter((item, index) => {
            return index < this.words.length / 2;
          });
        } else {
          return [
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют"
          ];
        }
      },
      bottomWords: () => {
        if (this.words.length > 0) {
          return this.words.filter((item, index) => {
            return index >= this.words.length / 2;
          });
        } else {
          return [
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют",
            "Ключевые слова отсутствуют"
          ];
        }
      },
      words: [],
      carousel: {
        entities: [],
        keyWords: {},
        entitiesMessages: {},
      },
      currenEntityText: "",
      currentMessage: "",
      entitryIndex: -1,
      sourceMessageIndex: 0,
    }
  },
  methods: {
    refreshCurrentEntity() {
      this.currenEntityText = this.carousel.entities[this.entitryIndex].text;
      this.words = this.carousel.keyWords[this.currenEntityText];

      this.sourceMessageIndex = 0;
    },
    nextEntity() {
      if (this.carousel.entities.length > 0) {
        this.entitryIndex += 1;
        if (this.entitryIndex >= this.carousel.entities.length) {
          this.entitryIndex = 0;
        }
        this.refreshCurrentEntity();
      }
    },
    previouseEntity() {
      if (this.carousel.entities.length > 0) {
        if (this.entitryIndex > 0) {
          this.entitryIndex -= 1;
          this.refreshCurrentEntity();
        }
      }
    },
    onPageClick(e) {
      this.nextEntity();
      this.nextSourceMessage();

      clearInterval(this.updateEntityTimer);
      clearInterval(this.updateMessageTimer);

      this.updateEntityTimer = setInterval(() => {
        this.nextEntity();
      }, 30000);
      this.updateMessageTimer = setInterval(() => {
        this.nextSourceMessage();
      }, 5000);
    },
    nextSourceMessage() {
      let currentMessages = this.carousel.entitiesMessages[this.currenEntityText];
      if (currentMessages != null) {
        if (currentMessages.length > 0) {
          this.currentMessage = currentMessages[this.sourceMessageIndex];

          this.sourceMessageIndex += 1;
          if (this.sourceMessageIndex >= currentMessages.length) {
            this.sourceMessageIndex = 0;
          }
        } else
          this.currentMessage = "Исходные сообщения не найдены. Перезагрузите страницу и обратитесь к администратору сайта";
      } else {
        this.currentMessage = "Исходные сообщения не найдены. Перезагрузите страницу и обратитесь к администратору сайта";
      }
    },
    getSourceIdendifier() {
      return this.$route.params.source;
    },
    getHostname() {
      if (this.$route.params.stream == "SberBA") {
        return this.$hostname_ba;
      } else {
        return this.$hostname_ba_macro;
      }

    },
    getTitle() {
      if (this.$route.params.stream == "SberBA") {
        return 'Растущие сюжеты из социальных медиа о Сбере';
      } else {
        return 'Растущие сюжеты из социальных медиа';
      }


    },
    loadData() {
      this.$vs.loading({
        target: this.$refs.imgMainBg,
        scale: 1,
        opacity: 0.5,
      });

      this.axios({
        method: 'get',
        url: '' + this.getHostname() + "/carusel/get-carusel-data-dedup",
      })
        .then((resp) => {

          this.carousel.entities = resp.data.data.filter(i => i.z_score > 2.5 && i.ner_type != "LOC");

          this.carousel.entities.forEach(entity => {
            this.carousel.keyWords[entity.text] = (entity.keywords) ? entity.keywords.map((word) => [word, 6]) : [];
            this.carousel.entitiesMessages[entity.text] = (entity.messages) ? entity.messages : [];
          });

          this.nextEntity();
          this.nextSourceMessage();

          clearInterval(this.updateEntityTimer);
          clearInterval(this.updateMessageTimer);

          this.updateEntityTimer = setInterval(() => {
            this.nextEntity();
          }, 30000);
          this.updateMessageTimer = setInterval(() => {
            this.nextSourceMessage();
          }, 5000);

          this.lastUpdateTime = new Date().toLocaleTimeString();
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
          this.currentMessage = "Исходные сообщения не найдены. Перезагрузите страницу и обратитесь к администратору сайта";
          if (error.config.params.token == null) {
            this.$router.push({ path: '/auth/login' });
          }
        });
    },
    globalKeyHandler(e) {
      if (e.key == "ArrowRight") {
        this.nextEntity();
        this.nextSourceMessage();

        clearInterval(this.updateEntityTimer);
        clearInterval(this.updateMessageTimer);

        this.updateEntityTimer = setInterval(() => {
          this.nextEntity();
        }, 30000);
        this.updateMessageTimer = setInterval(() => {
          this.nextSourceMessage();
        }, 5000);
      }
      if (e.key == "ArrowLeft") {
        this.previouseEntity();
        this.nextSourceMessage();

        clearInterval(this.updateEntityTimer);
        clearInterval(this.updateMessageTimer);

        this.updateEntityTimer = setInterval(() => {
          this.nextEntity();
        }, 30000);
        this.updateMessageTimer = setInterval(() => {
          this.nextSourceMessage();
        }, 5000);
      }
    },
  },
  mounted() {
    this.loadData();
    setInterval(() => {
      this.entitryIndex = 0;
      this.sourceMessageIndex = 0;

      this.loadData();
    }, 1000 * 30 * 2 * 15); // number of miliseconds * seconds for one entity * two entity in a minute * 15 minutes for recalculating entities
    document.onkeydown = this.globalKeyHandler;
  }
};
</script>

<template>
  <div @click="onPageClick">
    <div><img src="background.svg" id="bg"></div>
    <div class="top-title">
      <p>{{ getTitle() }}</p>
    </div>
    <div class="top-words-container">
      <vue-word-cloud :words="topWords()" :spacing=3 color="#9b9b9b" font-family="SBSansDisplayLight">
        <template slot-scope="{text, weight, word}">
          <div class="wordBox grey-text">
            {{ text }}
          </div>
        </template>
      </vue-word-cloud>
    </div>
    <div>
      <p class="entity-index-title">{{ entitryIndex + 1 }} место</p>
      <p class="entity-text-title">
        {{ currenEntityText }}</p>
    </div>
    <div class="bottom-words-container">
      <vue-word-cloud :words="bottomWords()" :spacing=3 color="#9b9b9b" font-family="SBSansDisplayLight">
        <template slot-scope="{text, weight, word}">
          <div class="wordBox grey-text">
            {{ text }}
          </div>
        </template>
      </vue-word-cloud>
    </div>
    <div>
      <div class="right-docker-box">
        <div class="card padded-card message"
          style="background: linear-gradient(158.06deg, #0E4B47 -9.82%, #0F2920 100.79%), #0F1C2D;border-radius: 24px;">
          <div class="card-body">
            <h3 class="msg__header">Сообщения</h3>
            <p class="msg__text">
              <text-highlight :queries="flatWords().map(i => { return ' ' + i + ' '; })">{{ currentMessage
              }}</text-highlight>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="time-box">Время последнего обновления: {{ lastUpdateTime }}</div>
  </div>
</template>

<style>
.message {
  font-family: SBSansTextLight;
  font-size: 2vmin;
  line-height: 4vh;
  color: #B6CCE7;
  max-height: 50vh;
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
}

.msg {
  background: linear-gradient(158.06deg, #0e4b47 -9.82%, #0f2920 100.79%),
    #0f1c2d;
  max-height: 44vh;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-left: 3rem;
}

.msg__header {
  font-family: SBSansDisplayRegular;
  font-weight: normal;
  font-size: 3vmin;
  /*

  */
  line-height: 3.5rem;
  /* or 150% */
  color: #FFFFFF;
}

.msg__text {
  font-family: SBSansTextRegular;
  font-size: 2vmin;
  line-height: 2.5vmin;
  color: #FFFFFF;
  margin-top: .75vmin;
}

.padded-card {
  padding: 10px;
  max-height: 70vh;
}

.wordBox {
  font-size: 36;
  font-family: "SBSansDisplayRegular";
}

.entity-index-title {
  font-family: SBSansDisplayRegular;
  font-size: 4vmin;
  line-height: 1;
  text-transform: lowercase;
  /* or 84% */
  color: #3684eb;
  top: 39vh;
  left: 13vw;
  position: absolute;
}

.entity-text-title {
  font-family: SBSansDisplayLight;
  font-size: 7vmin;
  line-height: 1;
  text-transform: lowercase;
  /* or 41% */
  color: #ffffff;
  top: 47vh;
  left: 13vw;
  width: 52vw;
  word-wrap: break-word;
  position: absolute;
}

.top-words-container {
  position: absolute;
  width: 62vw;
  height: 18vh;
  left: 7vw;
  top: 20vh;
}

.bottom-words-container {
  position: absolute;
  width: 62vw;
  height: 18vh;
  left: 7vw;
  bottom: 6vh;
}

.right-docker-box {
  position: absolute;
  width: 27vw;
  height: 15vh;
  right: 2vw;
  top: 25vh;
}

.grey-text {
  color: rgba(255, 255, 255, .6);
}

.top-title {
  font-family: SBSansDisplayLight;
  font-size: 6vmin;
  position: absolute;
  top: 6vh;
  left: 7vw;
  margin-right: 3vw;
  background: linear-gradient(236.8deg, #1C72CE 18.72%, rgba(28, 114, 206, 0) 46.97%),
    linear-gradient(296.66deg, #09A552 3.98%, rgba(9, 165, 82, 0) 48.42%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body,
html {
  height: 100%;
  /* Hide scrollbars */
  overflow: hidden;
  font-family: SBSansTextRegular, Verdana, sans-serif;
}

#bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -5000;
}

#time-box {
  position: absolute;
  left: 13vw;
  bottom: 1.5vh;
  font-family: SBSansDisplayLight;
  font-size: 2vmin;
}

@media all and (orientation:portrait) {
  .top-title {
    left: 13vw;
  }

  .message {
    max-height: 20vh;
  }

  .right-docker-box {
    position: absolute;
    top: 54vh;
    width: 80vw;
    height: 45vh;
    left: 10vw;
    right: 10vw;
  }

  .entity-index-title {
    font-family: SBSansDisplayRegular;
    top: 20vh;
    position: absolute;
  }

  .entity-text-title {
    top: 23vh;
    left: 13vw;
    width: 52vw;
    position: absolute;
  }

  .top-words-container {
    position: absolute;
    width: 86vw;
    height: 18vh;
    left: 7vw;
    top: 33vh;
  }

  .bottom-words-container {
    position: absolute;
    width: 86vw;
    height: 18vh;
    left: 7vw;
    bottom: 12vh;
  }
}
</style>
