var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":_vm.onPageClick}},[_vm._m(0),_c('div',{staticClass:"top-title"},[_c('p',[_vm._v(_vm._s(_vm.getTitle()))])]),_c('div',{staticClass:"top-words-container"},[_c('vue-word-cloud',{attrs:{"words":_vm.topWords(),"spacing":3,"color":"#9b9b9b","font-family":"SBSansDisplayLight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
var weight = ref.weight;
var word = ref.word;
return [_c('div',{staticClass:"wordBox grey-text"},[_vm._v(" "+_vm._s(text)+" ")])]}}])})],1),_c('div',[_c('p',{staticClass:"entity-index-title"},[_vm._v(_vm._s(_vm.entitryIndex + 1)+" место")]),_c('p',{staticClass:"entity-text-title"},[_vm._v(" "+_vm._s(_vm.currenEntityText))])]),_c('div',{staticClass:"bottom-words-container"},[_c('vue-word-cloud',{attrs:{"words":_vm.bottomWords(),"spacing":3,"color":"#9b9b9b","font-family":"SBSansDisplayLight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
var weight = ref.weight;
var word = ref.word;
return [_c('div',{staticClass:"wordBox grey-text"},[_vm._v(" "+_vm._s(text)+" ")])]}}])})],1),_c('div',[_c('div',{staticClass:"right-docker-box"},[_c('div',{staticClass:"card padded-card message",staticStyle:{"background":"linear-gradient(158.06deg, #0E4B47 -9.82%, #0F2920 100.79%), #0F1C2D","border-radius":"24px"}},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"msg__header"},[_vm._v("Сообщения")]),_c('p',{staticClass:"msg__text"},[_c('text-highlight',{attrs:{"queries":_vm.flatWords().map(function (i) { return ' ' + i + ' '; })}},[_vm._v(_vm._s(_vm.currentMessage))])],1)])])])]),_c('div',{attrs:{"id":"time-box"}},[_vm._v("Время последнего обновления: "+_vm._s(_vm.lastUpdateTime))])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"background.svg","id":"bg"}})])}]

export { render, staticRenderFns }